<template>
    <v-card-text>
    <v-layout row wrap>
    <v-flex lg12 xs12>
        <v-subheader class="secondary--text font-weight-black">Informacion del Registro de Curso</v-subheader>
    </v-flex>
    <v-flex lg8 xs12>
        <v-layout row wrap align-center>
            <v-flex lg4 xs6 class="text-right font-weight-bold grey--text pr-2">Fecha Registro al Curso:</v-flex>
            <v-flex lg8 xs6 class="py-2"><v-icon s>mdi-calendar</v-icon> {{formatDateText(registroCurso.fechaRegistro)}}</v-flex>
            <v-flex lg4 xs6 class="text-right font-weight-bold grey--text pr-2">Codigo Registro:</v-flex>
            <v-flex lg8 xs6 class="py-2 font-weight-bold">{{registroCurso.codigoRegistroCurso}}</v-flex>
            <template>
                <v-flex lg4 xs6 class="text-right font-weight-bold grey--text pr-2">Tipo Registro:</v-flex>
                <v-flex lg8 xs6 class="py-2 font-weight-bold">{{registroCurso.registroCiudadania?'Ciudadanía Digital':'OPERADOR RPA'}}</v-flex>
            </template>
            <template v-if="registroCurso.fechaMarcadoAsistencia">
                <v-flex lg4 xs6 class="text-right font-weight-bold grey--text pr-2">Fecha de Asistencia:</v-flex>
                <v-flex lg8 xs6 class="py-2 font-weight-bold">{{formatDateText(registroCurso.fechaMarcadoAsistencia)}}</v-flex>
                <v-flex lg4 xs6 class="text-right font-weight-bold grey--text pr-2">Certificado:</v-flex>
                <v-flex lg8 xs6 class="py-2 font-weight-bold">
                   <a :href="docUrl+registroCurso.rutaCertificado" target="_blank"> {{registroCurso.rutaCertificado}}</a>
                </v-flex>
            </template>
            <template  v-if="registroCurso.fechaMarcadoEntregaCertificado">
                <v-flex lg4 xs6 class="text-right font-weight-bold grey--text pr-2">Fecha Entrega Certificado:</v-flex>
                <v-flex lg8 xs6 class="py-2 font-weight-bold">{{registroCurso.fechaMarcadoEntregaCertificado}}</v-flex>
            </template>
        </v-layout>
    </v-flex>
    </v-layout>
</v-card-text>
</template>
<script>
import mixinDateTime from "@/mixins/datetime"
export default {
    props:{
        registroCurso:Object
    },
    mixins: [mixinDateTime],
    data(){
        return{
            docUrl:process.env.VUE_APP_DOC_URL
        }
    }
}
</script>